import  React, { useState, useEffect } from "react"
// import { Link } from "gatsby"

//COMPONENTS
import Layout from "../../components/layout"
import Seo from "@components/Seo.js"
import Breadcrumbs from "../../components/Breadcrumbs"
import Select from "../../components/Select"
import BigTrial from "../../components/banners/BigTrial"
import Calculator from "../../components/Calculator"

// IMAGES
import Academic from "../../images/academic-level.svg"
import Pages from "../../images/pages.svg"
import Type_work from "../../images/type-work.svg"
import Urgency from "../../images/urgency.svg"
import PriceMessage from "../../images/price-message.svg"

// DATA
import price from '../../content/price.json'

const QuotePage = () => {
    const pageData = {
        title: 'Our Prices, Instant Quote Maker',
        description: `Find the prices for the exact writing service that you need here. Our pricing calculator can give you an instant quote.`,
        keywords: `how much is essay, term paper price, essay price, pay for essay, how much to pay for term paper`
    }
    const steps = [
        {
            name: 'Price Quote',
            path: '/order/quote'
        }
    ]
    const headerList= [
        {
            text: 'Academic level',
            img: Academic
        },
        {
            text: 'Number of pages',
            img: Pages
        },
        {
            text: 'Urgency',
            img: Urgency
        },
        {
            text: 'Type of work',
            img: Type_work
        }
    ]
    const { type } = price
    const { level } = price
    const time = price.turnaround

    const [currentType, setCurrentType] = useState({
        ...type[0]
    })
    const [currentLevel, setCurrentlevel] = useState({
        ...level[0]
    })

    const [showMobileTable, setShowMobileTable] = useState(false)

    const changeType = (item) => {
        setCurrentType({
            ...item
        })
    }

    const changeLevel = (item) => {
        setCurrentlevel({
            ...item
        })
    }


    const calcPriceDesktop = (level, time) => {
        return (Number(level) + Number(time) + Number(currentType.rate)).toFixed(0)
    }
    const calcPriceMobile = (time) => {
        return (Number(currentLevel.rate) + Number(time) + Number(currentType.rate)).toFixed(0)
    }
    const getLink = 'https://shop.customessaymeister.com/login'

    useEffect(() => {
        setShowMobileTable(window.innerWidth <= 992)
        window.addEventListener('resize', () => {
            setShowMobileTable(window.innerWidth <= 992)
        })
    }, [])

    return (
        <>
            <Seo {...pageData} />
            <Layout >
                <div className="quote-intro">
                    <div className="wrapper">
                        <div className="quote-intro--content">
                            <Breadcrumbs steps={steps}/>
                            <h1 className="page-intro__title">Prices</h1>
                            <p className="quote-intro--subtitle">The price for your order depends on the following factors:</p>
                            <div className="quote-intro--bottom">
                                {headerList.map((item, index) =>
                                    <div key={index} className="quote-intro--bottom-item">
                                        <img src={item.img} alt={item.text} />
                                        <span>{ item.text }</span>
                                    </div>
                                )}
                            </div>
                            <div className="quote-intro--content-logo">
                                <img src={PriceMessage} alt="price" />
                            </div>
                        </div>
                    </div>
                </div>


                <section>
                    <div className="wrapper">
                        <div className="quote-table">
                            <div className="quote-table__type">
                                <Select list={type} handlerChange={changeType} value={currentType.text} />
                                {showMobileTable &&
                                    <Select list={level} handlerChange={changeLevel} value={currentLevel.text} />
                                }
                            </div>
                            {!showMobileTable &&
                                <div className="quote-table__level">
                                    <div className="quote-table__item nohover"></div>
                                    {level.map((item, index) =>
                                        <div
                                            key={index}
                                            className="quote-table__item nohover"
                                        >
                                            { item.text }
                                        </div>
                                    )}
                                </div>
                            }

                            <div className="quote-table__rate">
                                {time.map((item, index) =>
                                    <div
                                        key={index}
                                        className="quote-table__list"
                                    >
                                        <div className="quote-table__item nohover">
                                            { item.long_name }
                                        </div>
                                        { !showMobileTable ?
                                            level.map((level, key) =>
                                                <div
                                                    key={key}
                                                    className="quote-table__item quote-table__item-desctop"
                                                >
                                                    <a
                                                        href={getLink}
                                                        className="quote-table__item-link"
                                                    >
                                                        ${ calcPriceDesktop(level.rate, item.rate) }
                                                    </a>
                                                </div>
                                            )
                                            :
                                            <div className="quote-table__item">
                                                <a href={getLink} className="quote-table__item-link"> </a>
                                                ${ calcPriceMobile(item.rate) }
                                            </div>
                                        }
                                    </div>
                                )}

                            </div>
                            <div className="quote-table__desc">
                                <p>
                                    The prices are per page in US dollars.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <BigTrial /> */}
                {/* <div className="quote-cost wrapper">
                    <h2>
                        See how <span>much your essay</span> will cost
                    </h2>
                    <p>
                        Find your type of paper, add pages, and select an academic level. Choose the longer deadline to make our essay
                        writing services cheaper. We can’t believe we’ve just told you that!
                    </p>
                    <ul className="quote-cost__list">
                        <li>FREE bibliography page</li>
                        <li>FREE title page</li>
                        <li>FREE formatting (APA, MLA, Harvard, Chicago/Turabian)</li>
                        <li>24x7 support</li>
                        <li>Part-by-part payment</li>
                        <li>PowerPoint slides</li>
                        <li>Review your writer’s samples</li>
                    </ul>
                </div>
                <Calculator /> */}
            </Layout>
        </>
    )
}

export default QuotePage
