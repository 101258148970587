import React, { useState, useRef, useEffect } from 'react'
import * as containerStyles from "./../styles/components/_select.module.scss"

const Select = ({ list, handlerChange, value }) => {
    const [showSelect, setShowSelect] = useState(false)

    const openToggleSelect = () => {
        setShowSelect(!showSelect)
    }

    const change = (item) => {
        handlerChange(item)
        setShowSelect(false)
    }
    

    const selectRef = useRef()

    useEffect(()=>{
        const clickOutside = (e) => {
            if(selectRef && !selectRef.current.contains(e.target)){
                setShowSelect(false)
            }
        }
        document.addEventListener('click', clickOutside)
        return function cleanup() {
            document.removeEventListener('click', clickOutside)
        }
    }, [])

    return(
        <div
            aria-hidden
            ref={selectRef}
            className={showSelect ? `${containerStyles.selectOpen} ${containerStyles.select}` : containerStyles.select}
            onClick={openToggleSelect}
        >
            <input
                value={value}
                type="text"
                readOnly
            />
            {  showSelect &&
                <ul className={containerStyles.selectList}>
                    {list.map((item, index) => 
                        <li
                            aria-hidden
                            key={index}
                            className={containerStyles.selectListItem}
                            onClick={() => change(item) }
                        >
                            { item.text }
                        </li>
                    )}
                </ul>
            }
            
        </div>
    )
}

export default Select