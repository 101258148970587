import * as React from "react"

const Calculator = () => {
    return (
        <div className="calclulator-wrapper">
            <iframe
                data-src="https://calc.customessaymeister.com/advanced?hours=164"
                data-calclulator=""
                scrolling="no"
                style={{width: 100 + '%'}}
                className="calculator-iframe lazyload"
                title="calculator"
                loading="lazy"
            ></iframe>
        </div>
    )
}

export default Calculator
